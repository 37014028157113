<script lang="ts" setup>
const { t } = useT();
const { data: appInitData } = useAppInitData();
const {
	pageData,
	hotGames,
	jackpotGames,
	newGames,
	popularGames,
	lastGames,
	favoriteGames,
	seoData,
	filteredBanners,
	refresh
} = useHomePage();
refresh();
const isGuest = useIsGuest();
usePromotionsSocket();

const isD0User = computed(() => appInitData.value?.depositsCount === 0);

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{ name: "description", content: () => seoData.value?.seoDescription },
		{ name: "keywords", content: () => seoData.value?.seoKeywords }
	]
});
</script>
<template>
	<div class="main">
		<template v-if="!isGuest && !isD0User">
			<AText as="h1" variant="taiz" :modifiers="['semibold']">
				{{ t("Select your perfect coin bundle!") }}
			</AText>

			<LazyOHomeQuickCash />
		</template>

		<template v-else>
			<AText :modifiers="['bold', 'center']" as="h1" variant="taiz">
				{{ t("Leading Social Casino") }}
			</AText>
			<AText class="subtitle text-conakry" :modifiers="['bold', 'center']" as="p" variant="tanta">
				<i18n-t keypath="{key1} 100% {key2}">
					<template #key1>
						<span>{{ $t("Enjoy") }}</span>
					</template>
					<template #key2>
						<span class="text-chimoio">{{ $t("Free Games") }}</span>
					</template>
				</i18n-t>
			</AText>

			<LazyOHomeBanners v-if="filteredBanners && filteredBanners.length" :banners="filteredBanners" />
		</template>

		<LazyOHomeActivities />

		<template v-if="!isGuest">
			<LazyOHomeGames
				v-if="lastGames.length"
				:games="lastGames"
				:title="t('Recently Played')"
				icon="20/clock"
				:show-view-all="false"
			/>
			<LazyOHomeGames
				v-if="favoriteGames.length"
				:games="favoriteGames"
				:title="t('favorites')"
				category="favorites"
				icon="20/favorite"
			/>
		</template>
		<LazyOHomeActivityFeed />
		<OLazyComponent v-if="!isGuest" id="promotions">
			<LazyOHomePromotions />
		</OLazyComponent>
		<OLazyComponent v-if="popularGames.length" id="popularGames">
			<LazyOHomeGames
				:games="popularGames"
				:title="t('popular games')"
				icon="24/popular-games"
				category="popular-games"
				:rows-per-slide="3"
			/>
		</OLazyComponent>
		<OLazyComponent v-if="newGames.length" id="newGames">
			<LazyOHomeGames
				:games="newGames"
				:title="t('new games')"
				icon="20/new"
				category="new-games"
				:rows-per-slide="2"
			/>
		</OLazyComponent>
		<OLazyComponent id="jackpots">
			<LazyOHomeJackpot :jackpots="pageData?.jackpots" />
		</OLazyComponent>
		<OLazyComponent v-if="jackpotGames.length" id="jackpotGames">
			<LazyOHomeGames :games="jackpotGames" :title="t('jackpot games')" icon="20/slots-1" category="jackpot" />
		</OLazyComponent>
		<OLazyComponent v-if="hotGames.length" id="hotGames">
			<LazyOHomeGames :games="hotGames" :title="t('hot games')" icon="20/hot" category="hot-games" />
		</OLazyComponent>
	</div>
</template>
<style lang="scss" scoped>
.main {
	color: var(--cannes);
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		h1 {
			display: none;
		}

		.subtitle {
			font-size: 14px;
		}
	}

	.text-chimoio {
		color: var(--chimoio);
	}

	.subtitle {
		margin-top: gutter(0.5);

		span:first-of-type {
			font-weight: 400;
		}
	}

	.banners,
	.quick-cash,
	.jackpot {
		margin: gutter(3) 0;

		@include media-breakpoint-down(md) {
			margin: gutter(2.5) 0;
		}
	}

	&:deep(.skeleton-wrapper) {
		--a-skeleton-background-color: var(--a-button-navigation-border-color);

		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}
</style>
